import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2021-stories/more-stories"
// import { withPrefix } from 'gatsby'

import socialBanner from "../../images/fall-winter-2021/social-banners/olympic-gold-medalist-education-degree.jpg";

import Hero from "../../images/fall-winter-2021/olympic-gold-medalist-education-degree/melissa-seidemann-portrait.jpg"
// import melissaSeidemannGoldMedal from "../../images/fall-winter-2021/olympic-gold-medalist-education-degree/melissa-seidemann-gold-medal.jpg"
import melissaSeidemannPool from "../../images/fall-winter-2021/olympic-gold-medalist-education-degree/melissa-seidemann-pool.jpg"


export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var pageTitle = 'Next Goal for Olympic Gold Medalist Is an Education Degree';
    var pageDesc = 'World-class water polo player and three-time Olympic gold medalist Melissa Seidemann enjoys connecting with students as a water polo coach and dreams of one day teaching kindergarten.';
    var slug = 'olympic-gold-medalist-education-degree';


    var heroStyles = {
      backgroundColor: '#222',
      backgroundImage: `url(${Hero})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center',
      backgroundSize: 'cover',
      color: 'white',
      height: '900px'
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2021">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={slug} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s">{pageTitle}</h1>
            <span className="animated fadeInDown delay-1s byline">By Kendra Morales, Photos courtesy of Melissa Seidemann</span>
          </div>
        </div>
        <article className="wrap small">

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">

          <figure className="align-right large align-outside">
            <img src={melissaSeidemannPool} alt="Melissa Seidemann in pool playing water polo" />
            <figcaption>Melissa Seidemann</figcaption>
          </figure>

          <p>World-class water polo player and three-time Olympic gold medalist Melissa Seidemann enjoys connecting with students as a water polo coach and dreams of one day teaching kindergarten. At Cal State Fullerton, Seidemann is pursuing a single subject mathematics credential and a master’s degree in educational technology. She also graduated from CSUF’s multiple subject credential program in 2019 after earning a bachelor’s degree in psychology from Stanford University.</p>

          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">

          
          <p>
            <strong>What I'll remember most about the Tokyo Olympics is…</strong><br />
            The amazing views of the city in the mornings and evenings. Our village was right on the water and our travel to and from our venues allowed for awesome sightseeing. I will fondly remember how Japan worked to make our experience amazing. And, of course, I will remember achieving our goal of Olympic gold. 
          </p>

          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">

          
          <p>
            <strong>The most difficult challenge of training during a pandemic was…</strong><br />
            Not having access to a pool for almost four months. In land-based sports, you can replicate training movements almost anywhere. In water polo, without a pool you are out of luck! This posed a huge challenge for us to stay fit and healthy. When our pools did open back up, it was an uphill battle to return to our previous level of conditioning. Additionally, the pandemic made us realize how important it is to have the team together and unified. 
          </p>

          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            
          <p>
            <strong>I chose to become an educator because…</strong><br />
            I have an opportunity to make a positive impact on students’ character and confidence. It’s why I have always loved teaching and coaching.
          </p>

          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          
          <p>
            <strong>What I love most about studying at Cal State Fullerton is… </strong><br />
            The community. From the moment I enrolled, CSUF and my classmates have treated me like family. I have loved the support it has provided me to return to school in my late 20s, pursue my credential and create my educators network. <span className="end-mark" />
          </p>

          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">


          <p className="learn-more">Support the College of Education<br /><a href="https://campaign.fullerton.edu/education" target="_blank" rel="noreferrer">campaign.fullerton.edu/education</a></p>

          </ScrollAnimation>
        
        </article>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 