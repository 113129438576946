import React from "react"
import {fallWinter2021stories} from './stories';
// import ScrollAnimation from 'react-animate-on-scroll';
import Link from "gatsby-plugin-transition-link/AniLink";

export default class MoreStories extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    var skip = this.props.slug;
    // console.log(skip)

    const list = fallWinter2021stories.map(function(story){
      if (story.slug != skip) {

        var slug = "/fall-winter-2021/" + story.slug + "/";

        return (
          <li className="regular" key={story.slug}>
            <Link
            className="link"
            cover
            bg="#FF7900"
            direction="up"
            duration={1}
            duration={.75}
            to={slug} >
              <img src={story.thumbnail} alt="" className="link-image" />
              <span className="link-text">{story.title}</span>
            </Link>
          </li>
        )
      }
    })
    

    // console.log(stories);

    return (
      
        <section className="more-stories more-stories-fall-winter-2021">
          {/* <ScrollAnimation animateIn="fadeIn"> */}
          <div className="wrap">
            <h2 className="more-stories-title center">More Stories</h2>
            <ul className="related-story-list">
              {list}
            </ul>
          </div>
          {/* </ScrollAnimation> */}
        </section>
      
    );
  }
} 